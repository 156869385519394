import { UserService } from './../../../../services/user.service';
import { GLOBAL } from './../../../../services/global';
import { Router } from '@angular/router';
import { CustomerService } from '../../../../services/customer.service';
import { ExporterService } from '../../../../services/exporter.service';
import { InvoiceService } from '../../../../services/invoice.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';

import { Exporter } from '../../../../models/exporter';
import { Customer } from '../../../../models/customer';
import { Company } from '../../../../models/company';
import { Invoice } from '../../../../models/invoice';
import { User } from '../../../../models/user';

import * as _moment from 'moment';
import * as _ from 'lodash';

import { SaleService } from '../../../../services/sale.service';
import { Sale } from '../../../../models/sale';
import { ReferralService } from '../../../../services/referral.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public sales: Sale[] = [];
  public indexSelected: any = {};
  public referral: any = {};
  public identity;
  public totalNet = 0;
  public currency;
  public isv;
  public total;
  public price = 0;
  public totalSale = 0;
  public balanceStatus: any = {};
  public company: Company;
  public newCompany: any;
  public exonerated = true;

  public filteredExporters: Exporter[];
  public filteredCurtomers: Customer[];
  public exportersData: Exporter[];
  public customersData: Customer[];

  public bankCtrl: FormControl = new FormControl();
  public bankFilterCtrl: FormControl = new FormControl();

  constructor(
    private userService: UserService,
    private _referralService: ReferralService,
    private _exporterService: ExporterService,
    private _invoiceService: InvoiceService,
    private _customerService: CustomerService,
    private fb: FormBuilder,
    private router: Router,
    ) {
      this.identity = this.userService.getCompany()._id;
      this.userService.getCurrentCompany(this.identity).subscribe(res => {
        this.newCompany = res;
        console.log(this.newCompany);
      });
  }

  ngOnInit() {
    this.currency = GLOBAL.currency;
    this.createForm();
    this.getSales();
    this.getDataFromServices();
    this.listenersFormChanges();
  }

  getSales(): void {
    this._referralService.getPendingReferrals().subscribe((data) => {
      if (data !== null) {
        this.sales = data;
        console.log(data);
      } else {}
    }, err => console.error('Error al obtener ventas: ', err));
  }

  getDataFromServices() {

    this._customerService.getCustomers().subscribe(resp => {
        this.customersData = resp;
        this.filteredCurtomers = resp;
     });

    this._exporterService.getExporters().subscribe(resp => {
      this.exportersData = resp;
      console.log(resp);
      this.filteredExporters = resp;
    });

  }

  listenersFormChanges() {
    this.form.get('customerName').valueChanges.subscribe(name => {
      this.filteredCurtomers = this.customersData
      .filter((customer: Customer) => this.existString(customer.name, name));
    });

    this.form.get('exporterName').valueChanges.subscribe(name => {
      this.filteredExporters = this.exportersData
      .filter((exporter: Exporter) => this.existString(exporter.name, name));
    });

    this.form.get('price').valueChanges.subscribe(price => {
      this.price = +price || 1;
      Object.keys(this.indexSelected).forEach(key => {
        this.indexSelected[key].sale_price = this.price;
      });
      this.getSum();
    });

  }

  public highlight(sale: any): void {
    sale['tpn'] = sale.hasOwnProperty('tpn') ? sale.tpn : sale.total_qq_ps;
    sale['sale_price'] = this.price || 1;
    if (this.indexSelected.hasOwnProperty(sale._id)) {
      delete this.indexSelected[sale._id];
    } else {
      this.indexSelected[sale._id] = { total_qq_ps: sale.tpn, tpn: sale.tpn, bags: sale.tbags, sale_price: this.price };
    }
    this.getSum();
    console.log(this.indexSelected);
  }

  getSum(): void {
    let sale = 0;
    let isv = 0;
    const totalPs = Object.keys(this.indexSelected).reduce((acc, key) => acc + this.indexSelected[key].total_qq_ps, 0);
    const bags = Object.keys(this.indexSelected).reduce((acc, key) => acc + this.indexSelected[key].bags, 0);
    Object.keys(this.indexSelected).forEach(key => {
      const current: any = this.indexSelected[key];
      sale += current.total_qq_ps * current.sale_price;
      isv += (sale / (15/100+1));
    });
    this.referral.tpn = parseFloat(Number(totalPs).toFixed(2));
    this.referral.total_sales = parseFloat(Number(sale).toFixed(2));
    this.referral.subTotal = parseFloat(Number(sale).toFixed(2));
    this.referral.bags = bags;
    if (this.exonerated === true) {
      this.referral.isv = 0;
      this.referral.total = parseFloat(Number(sale).toFixed(2));
    } else {
      this.referral.isv = parseFloat(Number(isv).toFixed(2));
      this.referral.total = parseFloat(Number(sale+isv).toFixed(2));
    }
    this.referral.total_net = this.getTotalNet(this.referral, this.referral.total);
    console.log(this.referral);
  }

  existString(text1: string, text2: string): boolean {
    const status = text1
    .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    .indexOf(text2
      .toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1;
    return status;
  }

  saveData(): void {
    const index = this.indexSelected;
    const invoice = this.form.getRawValue();
    const propertiesToDelete = ['customer', 'exporter', 'certifications', 'cashCount'];

    propertiesToDelete.forEach(property => {
      invoice[property] === "" ? delete invoice[property] : null;
    });

    invoice.referrals = Object.keys(index);
    invoice.discount = 0;
    invoice.totalExonerated = 0;
    invoice.subTotal = this.referral.subTotal;
    invoice.isv = this.referral.isv;
    invoice.total = this.referral.total;
    console.log(invoice);

    this._invoiceService.addInvoice(invoice).subscribe(res => {
      console.log('agregada: ', res);
      this.updateSales(invoice.referrals);
      setTimeout(() => {
        this.router.navigateByUrl('/administracion/facturas');
      }, 500);
    });

  }

  getTotalNet(settlement: any, total): number {
    let total_net = total;
    const numbers = _.pick(settlement, ['tbags', 'tpb', 'ttare', 'tpn', 'total_qq_ps', 'total_qq_net']);
    Object.keys(numbers).forEach(key => {
      numbers[key] = isNaN(numbers[key]) ? 0 : numbers[key];
      total_net -= numbers[key];
    });
    return parseFloat(Number(total_net).toFixed(2));
  }

  sumBy(object: any, field: string): number {
    return Object.keys(object).reduce((acc, key) => acc + object[key][field] , 0);
  }

  mapBy (object: any, field: string): Object {
    return Object.keys(object).map(key => object[key][field]);
  }

  ngOnDestroy(): void {
  }

  updateSales(sales): void {
    sales.forEach((id, index) => {
      const update = {
        status: 'Pagado',
      }
      this._referralService.editReferral(id, update).subscribe(response => {
        console.log('Remision actualizado: ', response);
      }, (err: any) => console.error('Error al actualizar: ', err));
    });
  }

  createForm(): void {
    this.form = this.fb.group({
      date:              ['', Validators.required],
      exporterName:      [''],
      customerName:      [''],
      exporter:          [''],
      customer:          [''],
      selectionType: ['exporter'],
      price:             [''],
      description:       [''],
      total_sales: [this.referral.total_sales]
    });
  }

}
