import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { GLOBAL } from './global';

@Injectable()
export class UserService {
  public identity;
  public token;
  public url: string;

  constructor(
    private _http: Http,
    private http: HttpClient) {
    this.url = GLOBAL.url;
  }

  login(username, password) {
    const data = {
      username,
      password
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(`${this.url}/auth/login`, data, { headers: headers });

  }

  updateUser(user: any) {

    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.getToken()}`
    });

    return this._http.put(`${this.url}/update-user/${user._id}`, user, { headers: headers }).pipe(map(res => res.json()));

  }

  register(user_to_register) {
    const params = JSON.stringify(user_to_register);

    const headers = new Headers({ 'Content-Type': 'application/json' });

    return this._http.post(this.url + 'register', params, { headers: headers }).pipe(map(res => res.json()));

  }

  getIdentity() {
    const identity = JSON.parse(localStorage.getItem('user'));

    if (identity !== 'undefined' ) {
      this.identity = identity;
    } else {
      this.identity = null;
    }

    return this.identity;
  }

  getToken() {
    const token = sessionStorage.getItem('token');

    if (token !== 'undefined') {
      this.token = token;
    } else {
      this.token = null;
    }
    return this.token;
  }

}
