import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

import { InventoryService } from '../../services/inventory.service';
import { CategoryService } from '../../services/category.service';
import { UserService } from '../../services/user.service';

import { UserRules } from '../../models/user-rules';
import { Category } from '../../models/category';
import { Company } from '../../models/company';
import { User } from '../../models/user';
import { CompaniesService } from '../../services/companies.service';
import { GLOBAL } from '../../services/global';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
  providers: [UserService, InventoryService, CategoryService]
})
export class SettingsComponent implements OnInit, AfterViewInit {
  public title = 'Ajustes Generales';
  public company: Company;
  public newCompany: any;
  public identity;
  public token;
  public alertMessage = '';

  public bankCtrl: FormControl = new FormControl();
  public bankFilterCtrl: FormControl = new FormControl();
  public data: any[];
  public filteredBanks: ReplaySubject<Category[]> = new ReplaySubject<Category[]>(1);

  private _onDestroy = new Subject<void>();
  public form: FormGroup;
  public regionForm: FormGroup;
  public invoiceForm: FormGroup;
  public passwordForm: FormGroup;
  public users = [];
  public userRules = new UserRules;
  public userForm: FormGroup;
  public modules = [
    {
      text: 'DashBoard',
      key: 'dashboard'
    },
    {
      text: 'Notas de Peso',
      key: 'weight_notes'
    },
    {
      text: 'Secado',
      key: 'drieds'
    },
    {
      text: 'Administracion',
      key: 'admin'
    },
    {
      text: 'Exportaciones',
      key: 'exports'
    },
    {
      text: 'Produccion',
      key: 'production'
    },
    {
      text: 'Contabilidad',
      key: 'accounting'
    },
    {
      text: 'Reportes',
      key: 'reports'
    }];
  public currencyOptions = [
    { country: 'Honduras',  currency: 'L', preffix: true },
    { country: 'Guatemala', currency: 'Q', preffix: true },
    { country: 'USA',       currency: '$', preffix: true }
  ];

  public passwordHide = {
    pass1: true,
    pass2: true
  };

  constructor(
    private _categoryService: CategoryService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private _userService: UserService,
    private _companyService: CompaniesService,
    private _router: Router,
  ) {
    const identity = this._userService.getIdentity();
    this.company = identity.company;
    console.log('company: ', this.company);
    this._userService.getCurrentCompany(this.company._id).subscribe(res => {
      this.newCompany = res;
      console.log(this.newCompany);
      if (this.newCompany) {
this.invoiceForm.patchValue(this.newCompany.settings.invoice);
      }
    });
    this.createCategoryForm();
    this.createRegionForm();
    this.createInvoiceForm();
    this.createPasswordForm();
  }

  ngOnInit() {
    this.getCategories();
    this.createUserForm();
    this.getUsers();
    this.listener();
    /* const user = {
      userType: 1,
      username: 'lggutierrez',
      password: 'LGerard10',
      name: 'Luis Gerardo Gutierrez'
    };
    this._userService.createUser(user).subscribe((user_: any) => {
      console.log('Usuario creado: ', user_);
    }, err => console.error('Error al crear usuario: ', err)); */
    // console.log('users!: ', this._userService.getAllUsers());
  }

  ngAfterViewInit(): void {
    if (this.company.settings) {
      if (this.company.settings.config) {
        this.form.patchValue(this.company.settings.config);
      }
    }
if (this.company.settings.region) {
    const region =  {
      country:  !this.company.settings.region ? 'Honduras' : this.company.settings.region.country,
      currency: !this.company.settings.region ? 'L' : this.company.settings.region.currency,
    };
    this.regionForm.patchValue(region);
} else {
      const region =  {
      country:  'Honduras',
      currency: 'L',
    };
    this.regionForm.patchValue(region);
}

  }

  /* * --------------------------------------------- PANEL DE REGLAS DE USUARIOS ----------------------------- */
  createUserForm(): void {
    this.userForm = this.formBuilder.group({
      users: this.formBuilder.array([]),
    });
  }

  getUsers(): void {
    const control = <FormArray>this.userForm.controls['users'];
    this._userService.getUsers().subscribe((response: User[]) => {
      this.users = response;
      const users = response.filter(item => item.userType.description !== 'ADMIN');
      users.forEach(user => {
        control.push(this.addUser(user));
      });
      console.log('form: ', this.userForm.value);
    }, err => console.error('Error al obtener usuarios: ', err));
  }

  addUser(user: User): FormGroup {
    const currentRules = user.hasOwnProperty('rules') ? user.rules : new UserRules;
    currentRules.user = user;
    return this.formBuilder.group(currentRules);
  }

  selectRegion(region: any): void {
    this.regionForm.patchValue(region);
    console.log('selected region', this.regionForm);
  }

  saveSettings(): void {
    const rulesData: UserRules[] = _.cloneDeep(this.userForm.value.users);
    // console.log('rulesData: ', rulesData);
    rulesData.forEach((current, index) => {
      const user: User = _.cloneDeep(current.user);
      delete current.user;
      const rules = current;
      user.rules = rules;
      this._userService.saveRules(user._id, user).subscribe(response => {
        console.log('Reglas guardadas: ', response);
      }, err => console.error('Error al guardar reglas: ', err));
      if (index === rulesData.length - 1) {
        this.alertMessage = 'Los cambios han sido guardados';
        setTimeout(() => {
          this.alertMessage = '';
        }, 5000);
      }
    });
  }

  saveRegion(): void {
    const region = this.regionForm.getRawValue();
    console.log('region', region);
    GLOBAL.currency = region.currency;
    this._companyService.updateRegion(this.company._id, region).subscribe(() => {
      console.log('region actualizada');
    }, err => console.error('Error al actualizar region', err));
  }

  listener() {
    this.invoiceForm.get('invoiceRange').valueChanges.subscribe(value => {
      if (!isNaN(value)) {
        this.calculateRange(value);
      }
    });
    this.invoiceForm.get('invoiceCurrent').valueChanges.subscribe(value => {
      const data: any = this.getRange(this.invoiceForm.get('invoiceRange').value);
      if (value >= data.range1 && value <= data.range2) {
        const remaining = (data.range1 + data.total) - value;
        this.invoiceForm.get('invoiceRemaining').setValue(remaining);
        this.invoiceForm.get('invoiceRange').clearValidators();
      } else {
        this.alertMessage = 'Valor fuera del rango establecido!';
        this.invoiceForm.get('invoiceCurrent').setErrors({incorrect: true});
      }
    });
  }

  calculateRange(value: string): void {
    if (value.length === 32) {
      const data: any = this.getRange(value);
      this.invoiceForm.get('invoiceCurrent').setValue(data.range1);
      console.log('range1', data);
      if (data.total > 0) {
        this.invoiceForm.get('invoiceRange').clearValidators();
        this.invoiceForm.get('invoiceRemaining').setValue(data.total);
      } else {
        this.invoiceForm.get('invoiceRange').setErrors({incorrect: true});
      }
    } else {
      this.invoiceForm.get('invoiceRange').setErrors({incorrect: true});
    }
  }

  getRange(value: string): object {
    const range1 = Number(value.substring(0, 16).substring(8));
    const range2 = Number(value.substring(16).substring(8));
    return {range1, range2, total: range2 + 1 - range1};
  }

  saveInvoice(): void {
    const invoice = this.invoiceForm.getRawValue();

    console.log(this.newCompany);
    console.log(invoice);
    if (invoice) {
      this.newCompany.settings.invoice = invoice;
      console.log(this.newCompany);
      this._userService.updateUser(this.newCompany).subscribe(
        response => {
          if (!response.user) {
            this.alertMessage = 'Error al actualizar';
          } else {
            this.alertMessage = 'Configurado';
          }
        }
      );
    }
  }

  changePassword = (): void => {
    const password = this.passwordForm.getRawValue();
    console.log('password', password);
    this._userService.changePassword(password.password1).subscribe(() => {
      localStorage.removeItem('identity');
      sessionStorage.removeItem('token');
      localStorage.removeItem('company');
      localStorage.clear();
      this._router.navigateByUrl('/');
      location.reload();
    }, (err: HttpErrorResponse) => console.error('Error al cambiar contrasena', err));
  }
  /* * --------------------------------------------- FIN PANEL DE REGLAS DE USUARIOS ----------------------------- */

  /* * --------------------------------------------- PANEL DE CONFIGURACION DE CUENTAS --------------------------- */

  getCategories() {
    this._categoryService.getCategories().subscribe(
      (res) => {
        const categories = res as Category[];
        this.data = _.filter(categories, { 'type': 'Detalle' });
        this.bankCtrl.setValue('');
        this.filteredBanks.next(this.data.slice());
        this.bankFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterBanks();
          });
      }, error => console.error('Eror al obtener las cuentas: ', error)
    );
  }

  createCategoryForm(): void {
    this.form = this.formBuilder.group({
      uLost:   [''],
      uPeriod: [''],
      closing: [''],
    });
  }

  createInvoiceForm(): void {
    this.invoiceForm = this.formBuilder.group({
      deadline:         ['', Validators.required],
      invoiceRange:     ['', Validators.required],
      invoiceRemaining: ['', Validators.required],
      invoiceCurrent:   ['', Validators.required],
    });
  }

  createRegionForm(): void {
    this.regionForm = this.formBuilder.group({
      country:  [!this.company.settings.region ? 'Honduras' : '', Validators.required],
      currency: [!this.company.settings.region ? 'L' : '', Validators.required]
    });
  }

  createPasswordForm(): void {
    this.passwordForm = this.formBuilder.group({
      password1:  ['', Validators.required],
      password2:  ['', Validators.required]
    });
  }

  private filterBanks() {
    if (!this.data) {
      return;
    }
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.data.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredBanks.next(
      this.data.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  config() {
    const config = this.company.settings;
    console.log(this.company.settings);
    this.company.settings = Object.assign(this.company.settings, {
      config: {
        uLost: this.form.value.uLost,
        uPeriod: this.form.value.uPeriod,
        closing: this.form.value.closing,
      }
    });
    this.company.settings = config;
    console.log(this.company.settings);
    this._userService.updateUser(this.company).subscribe(
      response => {
        if (!response.user) {
          this.alertMessage = 'Error al actualizar las cuentas';
        } else {
          this.alertMessage = 'Las cuentas han sido actualizadas';
          localStorage.setItem('company', JSON.stringify(this.company));
        }
        setTimeout(() => {
          this.alertMessage = '';
        }, 5000);
      }
    );
  }

  /* * --------------------------------------------- FIN PANEL DE CONFIGURACION DE CUENTAS --------------------------- */

}
