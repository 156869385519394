import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';

import { AdvancePaymentService } from '../../../../services/advancePayment.service';
import { InvoiceService } from '../../../../services/invoice.service';
import { InventoryService } from '../../../../services/inventory.service';
import { ProductorService } from '../../../../services/productor.service';
import { PaymentService } from '../../../../services/payment.service';
import { PrintService } from '../../../../services/print.service';
import { ExcelService } from '../../../../services/excel.service';
import { UserService } from '../../../../services/user.service';
import { GLOBAL } from '../../../../services/global';

import { SeatAddModalComponent } from '../../../seat-add-modal/seat-add-modal.component';
import { DeleteModalComponent } from '../../../delete-modal/delete-modal.component';

import { CoffeeFarmer } from '../../../../models/productor';
import { Invoice } from '../../../../models/invoice';
import { SaleService } from '../../../../services/sale.service';
import { Sale } from '../../../../models/sale';
import { Inventory } from '../../../../models/inventory';
import { Payment } from '../../../../models/payment';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {


  public title = 'Facturas';
  public inventory: any = {};
  public identity;
  public identi;
  public harvests = ['Todas', '2018 / 2019', '2019 / 2020', '2020 / 2021', '2021 / 2022', '2022 / 2023', '2023 / 2024', '2024 / 2025'];
  public currentHarvest = '';
  public indexedInvoices: any = {};
  public loading = false;
  public dataTable;
  public form: FormGroup;
  public moreBtn = false;
  public alertMessage = '';
  public exist = 0;
  public totalInvoices = 0;
  public invoicesData: Invoice[] = [];
  public convertToExcel = [];
  public countInvoices = 0;
  public repetidos = [];
  public filteredCoffeeFarmers: CoffeeFarmer[] = [];
  public availableCoffeefarmers: CoffeeFarmer[] = [];
  public pipe = new DatePipe('es-HN');
  public status = ['Pendiente', 'Parcial', 'Pagado'];
  public displayedColumns: string[] = ['cod_set', 'date', 'coffeefarmer', 'total_qq', 'total',
    'total_deductions', 'total_net', 'status', 'observations', 'action' ];

  private tableRef: HTMLElement;
  private page = 0;
  private limit = 100;
  public currency;
  public tempData = {
    totalqq: 0,
    totalqqWn: 0,
    real: 0
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private _userService: UserService,
    private _invoiceService: InvoiceService,
    private _printService: PrintService,
    private _saleService: SaleService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.identity = this._userService.getCompany();
    this.identi = this._userService.getIdentity();
  }

  ngOnInit() {
    this.tableRef = document.getElementById('matTableInvoice');
    this.currentHarvest = this.identi.company.harvest;
    this.currency = GLOBAL.currency;
    if (!this.harvests.some(h => h === this.currentHarvest)) {
      this.harvests.push(this.currentHarvest);
    }
    this.getDataWithFilters();
  }

  ngAfterViewInit(): void {
    if (this.identity._id === '5f6b9d60e0ac8256dcb36e28') {
      this.displayedColumns.splice(7, 0, 'pending');
    }
    this.paginator._intl.itemsPerPageLabel = 'Registros por página';
  }

  getDataWithFilters(): void {
    this._invoiceService.getInvoices().subscribe(res => {
      this.loading = false;
      console.log(res);
        this.invoicesData = res;
        this.countInvoices = res.length;
    }, err => {
      console.error('ERROR EN Liquidaciones con filtro ', err);
      this.loading = false;
      this.alertMessage = 'No se pudieron obtener los registros';
    });
  }

  filterData(): void {
    this.page = 0;
    this.paginator.firstPage();
    this.getDataWithFilters();
  }

  changePage(paginator: MatPaginator) {
    this.limit = paginator.pageSize;
    this.page = paginator.pageIndex;
    const tableRef = window.document.getElementById('matTableInvoice');
    tableRef !== null ? tableRef.scrollTo(0, 0) : console.log('tableRefNull', tableRef);
    this.getDataWithFilters();
  }


  openDialogD(invoice) {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      panelClass: ['animate__animated', 'animate__bounceIn', 'outline_modal'],
      width: '400px',
      data: {
        header: 'Anular Remision',
        title: '',
        text: `¿Seguro que quieres anular <strong>#${invoice.seq}</strong>?`
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('el de aqui', invoice);
      if (result === 'confirm') {
        this.cancelInvoice(invoice);
      }
    });
  }

  print(sale: Invoice): void {
    console.log('Sale to print: ', sale);
    this._printService.getInvoice(sale._id).then((res: any) => {
      const blob = new Blob([res], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    })
    .catch(err => console.log('Error', err));
  }

  cancelInvoice(invoice) {
    invoice['enabled'] = false;
    this._invoiceService.editInvoice(invoice._id, invoice).subscribe(response => {
      console.log('Remision anulada: ', response);
      this.updateSales(invoice.sales);
      setTimeout(() => {
        this.getDataWithFilters();
      }, 1000);
      // todo : Anular asiento
    }, err => console.error('Error al anular Remision: ', err));
  }

  updateSales(sales): void {
    sales.forEach((id, index) => {
      const update = {
        invoiced: false,
      }
      this._saleService.editSale(id, update).subscribe(response => {
        console.log('Venta actualizado: ', response);
      }, (err: any) => console.error('Error al actualizar: ', err));
    });
  }

}
