import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ExcelService } from '../../../../services/excel.service';
import * as _ from 'lodash';
import { ChekkuService } from '../../../../services/chekku.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PrintService } from '../../../../services/print.service';
import { PolygonService } from '../../../../services/polygon.service';
import * as JSZip from 'jszip';
import { MatDialog } from '@angular/material';
import { GeoListService } from '../../../../services/geoList.service';

declare var google: any;
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  @ViewChild('googleMap') gmapElement: any;
  map: google.maps.Map;
  dataLayer = new google.maps.Data();
  apiLoaded: Observable<boolean>;
  scriptLoadingPromise: Promise<void>;
  loader: any;
  marker: any;
  currentPage = 1;
  isLoading = false;

  years: number[] = [2020, 2021, 2022, 2023];
  currentYear: number = 2023;
  center = {lat: 24, lng: 12};
  zoom = 15;
  loc;
  maps: google.maps.Map;
  private geocoder: google.maps.Geocoder;
  lat = 15.507190776629812;
  lng = -88.02471461460642;
  placeName;
  selectedLoc;
  checkinForm;
  checkinImage = '';
  titleForm = '';
  formId = '';
  infowindow: any;
  public allCheckins = [];
  public currentAllCheckins = [];
  public forms = [];
  public values = [];
  public excelData = [];
  public excelTitle;
  public page = 1;
  public form: FormGroup;
  excelHeaders: string[] = [];
  templateToExcel: string[][] = [this.excelHeaders, []];
  selectedFiles: FileList;
  polygon: google.maps.Polygon;
  polygonToDownload: any;
  satelliteImageUrl: string;
  selectedCheckins: any[] = [];
  list = [];
  filteredList = [];
  geoList = [];
  private zip = new JSZip();
  markers: google.maps.Marker[] = []; // Almacenar marcadores
  polygons: google.maps.Polygon[] = []; // Almacenar polígonos
  bounds = new google.maps.LatLngBounds(); // Límites del mapa
  public convertToExcel = [];

  constructor(
    private _chekkuService: ChekkuService,
    private fb: FormBuilder,
    private excelService: ExcelService,
    private _printService: PrintService,
    private _polygonService: PolygonService,
    public dialog: MatDialog,
    private _geoListServer: GeoListService,
  ) {}

  ngOnInit(): void {
    this.createForm();
    // this.getCheckins(this.currentPage);
    this.getList();
    this.listeners();
  }

  listeners(): void {
    this.form.get('filter').valueChanges.subscribe((value: any) => {
      this.filterList(value);
    });
  }

  filterList(searchTerm: string): void {
    const lowerSearchTerm = searchTerm.toLowerCase();
    console.log(lowerSearchTerm);
    this.filteredList = this.list.filter(item =>
      item.name.toLowerCase().includes(lowerSearchTerm)
    );
  }

  createForm(): void {
    this.form = this.fb.group({
      filter: [''],
    });
  }

  getList(page?: number) {
    this._geoListServer.getList().subscribe(res => {
      this.list = res;
      this.filteredList = [...this.list];
      this.onSelect(res[0]);
      this.initMap();
    });
  }

  loadMore() {
    this.currentPage++;
    // this.getCheckins(this.currentPage);
  }

  exportTemplateAsExcel() {
    this.excelService.exportAsExcelFile(this.excelData, this.excelTitle);
  }

  init() {
    const mapProp = {
      center: new google.maps.LatLng(this.lat , this.lng),
      zoom: 14,
      mapTypeId: google.maps.MapTypeId.HYBRID
    };
    
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    this.marker = new google.maps.Marker({ position: mapProp.center });
    this.marker.setMap(this.map);
  
  }

initMap(): void {
  const mapOptions = {
    center: new google.maps.LatLng(this.lat, this.lng),
    zoom: 12,
    mapTypeId: google.maps.MapTypeId.HYBRID,
  };

  this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptions);
  const bounds = new google.maps.LatLngBounds();

  this.geoList.forEach((checkin) => {
    if (checkin.polygon && checkin.polygon.geometry) {
      const polygon = this.addPolygonToMap(checkin.polygon.geometry);
      this.extendBoundsWithPolygon(polygon, bounds); // Expandir los límites del mapa
    } else {
      const latLng = new google.maps.LatLng(
        checkin.loc.coordinates[1],
        checkin.loc.coordinates[0]
      );
      this.addMarkerToMap(latLng, checkin);
      bounds.extend(latLng); // Agregar el marcador y extender los límites
    }
  });

  this.map.fitBounds(bounds); // Ajustar el mapa para mostrar todos los elementos
}

addMarkerToMap(latLng: google.maps.LatLng, checkin: any): void {
  const marker = new google.maps.Marker({
    position: latLng,
    map: this.map,
    title: checkin.customer.name,
  });

  const infoWindow = new google.maps.InfoWindow({
    content: `<div><strong>${checkin.customer.name}</strong></div>`,
  });

  marker.addListener('click', () => {
    infoWindow.open(this.map, marker);
  });
}

addPolygonToMap(geometry: any): google.maps.Polygon {
  const paths = geometry.coordinates[0].map(
    (coord: [number, number]) => ({ lat: coord[1], lng: coord[0] })
  );

  const polygon = new google.maps.Polygon({
    paths: paths,
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
  });

  polygon.setMap(this.map); // Agregar cada polígono al mapa

  return polygon; // Devolver la instancia del polígono para el manejo de límites
}

extendBoundsWithPolygon(polygon: google.maps.Polygon, bounds: google.maps.LatLngBounds): void {
  const path = polygon.getPath();
  path.forEach((latLng: google.maps.LatLng) => {
    bounds.extend(latLng);
  });
}

selectFarmer(geo: any) {
  console.log('Seleccionado:', geo);

  this._polygonService.getPolygon(geo._id).subscribe((res) => {
    console.log('Respuesta del polígono:', res);

    const payload = this.buildGeoJsonPayload(geo, res);
    console.log('Payload enviado:', payload);

    this._polygonService.postGeoJson(payload).subscribe(response => {
      console.log('Respuesta del POST:', response);
    });
  });
}

buildGeoJsonPayload(selectedItem: any, polygonData: any): any {
  if (polygonData.error !== 'not') {
    return {
      type: polygonData.polygon.type,
      name: polygonData.polygon.name,
      crs: polygonData.polygon.crs,
      features: polygonData.polygon.features,
    }
  } else if (polygonData.error === 'not') {
    const [lng, lat] = selectedItem.loc.coordinates;
    return {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [[[lng, lat]]]
          }
        }
      ]
    };
  } else {
    throw new Error("No valid coordinates found.");
  }
}

onSelect(item: any): void {
  console.log(item);

  this.loc = item;
  this.selectedLoc = item;

  const mapProp = {
    center: new google.maps.LatLng(14.0833, -87.2167), // Coordenadas por defecto
    zoom: 12,
    mapTypeId: google.maps.MapTypeId.HYBRID
  };

  this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

  const bounds = new google.maps.LatLngBounds();

  // Limpiar cualquier capa de datos anterior
  if (this.dataLayer) {
    this.dataLayer.setMap(null);
  }
  this.dataLayer = new google.maps.Data();
  this.dataLayer.setMap(this.map);

  this.selectedCheckins = this.loc.geoList;
  this.selectedCheckins.forEach((geo) => {
    this._polygonService.getPolygon(geo._id).subscribe((res) => {
      if (res.error === 'not') {
        this.addMarker(geo.loc.coordinates, geo.customer.name, bounds);
      } else {
        console.log(res);
        const poly = {
          type: res.polygon.type,
          name: res.polygon.name,
          crs: res.polygon.crs,
          features: res.polygon.features,
        }
        geo.polygon = poly;
        this.showPolygon(res.polygon, bounds);
      }

      this.map.fitBounds(bounds);
    });
  });
  
}

getAreaValu(farmer: any): string {
  if (!farmer || !Array.isArray(farmer.form)) return 'N/A';

  for (const form of farmer.form) {
    if (Array.isArray(form.content_form)) {
      for (const field of form.content_form) {
        const fieldName = field.field.toLowerCase();
        if (['area', 'hectareas', 'manzanas'].includes(fieldName)) {
          return field.value || 'N/A';
        }
      }
    }
  }

  return 'N/A';
}

getAreaValue(farmer: any): string {
  if (!farmer || !Array.isArray(farmer.form)) return 'N/A';

  for (const form of farmer.form) {
    if (Array.isArray(form.content_form)) {
      for (const field of form.content_form) {
        const fieldName = field && field.field && typeof field.field === 'string' ? field.field.toLowerCase() : '';
        if (fieldName && ['area', 'hectareas', 'manzanas'].includes(fieldName)) {
          return field.value || 'N/A';
        }
      }
    }
  }

  return 'N/A';
}

downloadExcel() {
  this.convertToExcel = this.selectedCheckins;

  this.convertToExcel.forEach((element: any, i) => {
    if (element === undefined) {
      console.error('Undefined element: ', element, i);
      return;
    }

    const normalizedFields = (element.form || []).reduce((acc, form) => {
      const formFields = form.content_form || [];
      formFields.forEach((field) => {
        if (field.props && field.props.title && field.value) {
          const normalizedKey = field.props.title
            .toLowerCase()
            .replace(/\s+/g, '')
            .replace(/[-_\/\\]/g, '')
            .replace(/[^\w]/g, '');
          acc[normalizedKey] = field.value;
        }
      });
      return acc;
    }, {});

    const rows = {
      Nombre: normalizedFields['nombredelproductor'] || (element.customer.name || ''),
      Aldea: normalizedFields['aldea'] || '',
      Municipio: normalizedFields['municipio'] || '',
      Departamento: normalizedFields['departamento'] || '',
      Zona: normalizedFields['zona'] || '',
      ID_finca: element._id || '',
      Identidad: normalizedFields['identidad'] || '',
      'Clave-Ihcafe': normalizedFields['claveihcafe'] || '',
      Latitud: element.loc.coordinates[1] || '',
      Longitud: element.loc.coordinates[0] || '',
      'Requiere Polígono': element.polygon ? 'Sí' : 'No',
      'Polígono': JSON.stringify(element.polygon) || '',
      DB: 'Tracify',
    };

    this.convertToExcel[i] = rows;
  });

  this.excelService.exportAsExcelFile(this.convertToExcel, 'GeoList');
}

print(checkin) {
  console.log(checkin);
  this._printService.getCheckin(checkin._id, this.page).then((res: any) => {
    const blob = new Blob([res], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');

    console.log(blob, res, blobUrl);
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  })
  .catch(err => console.log('Error', err));
}

downloadGeoJson() {
  const geojson = this.polygonToDownload;
  const blob = new Blob([JSON.stringify(geojson)], { type: 'application/json' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'polygon.geojson';
  a.click();
  window.URL.revokeObjectURL(url);
}

addMarker(coordinates: [number, number], name: string, bounds: google.maps.LatLngBounds): void {
  if (coordinates) {
    const position = new google.maps.LatLng(coordinates[1], coordinates[0]);

    const marker = new google.maps.Marker({
      position: position,
      map: this.map,
    });

    const infowindow = new google.maps.InfoWindow({
      content: name,
    });

    marker.addListener('click', () => infowindow.open(this.map, marker));
    bounds.extend(position); // Expandir los límites para incluir el marcador
  } else {
    console.warn('Coordenadas no válidas:', coordinates);
  }
}

showPolygon(geojson, bounds) {
  try {
    console.log(geojson);
    this.dataLayer.setMap(null);
    this.dataLayer = new google.maps.Data();

    this.dataLayer.addGeoJson(geojson);
    this.dataLayer.setStyle({
      fillColor: 'green',
      strokeColor: 'green',
      strokeWeight: 2
    });
    this.dataLayer.setMap(this.map);
    this.polygonToDownload = {
      type: geojson.type,
      name: geojson.name,
      crs: geojson.crs,
      features: geojson.features,
    }
    console.log(this.polygonToDownload);

    if (!bounds) {
      this.initMap();
    }
    this.dataLayer.forEach(feature => {
      this.processPoints(feature.getGeometry(), bounds.extend, bounds);
    });
    // this.map.fitBounds(bounds);

  } catch (error) {
    console.error('Error adding GeoJSON to map:', error);
  }
}

convertToGeoJSON(geometry: any): any {
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: geometry,
        properties: {},
      },
    ],
  };
}

  processPoints(geometry: any, callback: Function, thisArg: any): void {
    if (geometry instanceof google.maps.LatLng) {
      callback.call(thisArg, geometry);
    } else if (geometry instanceof google.maps.Data.Point) {
      callback.call(thisArg, geometry.get());
    } else if (
      geometry instanceof google.maps.Data.MultiPoint || 
      geometry instanceof google.maps.Data.LineString || 
      geometry instanceof google.maps.Data.LinearRing || 
      geometry instanceof google.maps.Data.MultiLineString || 
      geometry instanceof google.maps.Data.Polygon || 
      geometry instanceof google.maps.Data.MultiPolygon
    ) {
      const geometryArray: any[] = geometry.getArray();
      if (Array.isArray(geometryArray)) {
        geometryArray.forEach((g: any) => this.processPoints(g, callback, thisArg));
      } else {
        console.error("Expected geometry.getArray() to return an array");
      }
    } else {
      console.error("Unknown geometry type", geometry);
    }
  }

  downloadSelectedCheckins(): void {
    const folderName = new Date().toISOString().split('T')[0];

    const folder = this.zip.folder(folderName);

    if (folder) {
      this.selectedCheckins.forEach((checkin) => {
        const customerName = this.cleanFileName(checkin.customer && checkin.customer.name ? checkin.customer.name : 'Unknown');
        const fileName = `${customerName}.geojson`;

        if (checkin.polygon === true) {
          folder.file(fileName, JSON.stringify(checkin));
        } else {
          const geojson = this.getGeoJsonForCheckin(checkin);
          folder.file(fileName, JSON.stringify(geojson));
        }
      });

      this.zip.generateAsync({ type: 'blob' }).then((content) => {
        const url = window.URL.createObjectURL(content);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${folderName}.zip`;
        a.click();
        window.URL.revokeObjectURL(url);
      });
    } else {
      console.error('Error al crear la carpeta en el zip');
    }

    this.selectedCheckins = [];
  }

  cleanFileName(name: string): string {
    return name.replace(/[^a-z0-9]/gi, '_').toLowerCase(); // Reemplazar caracteres especiales con '_'
  }

  getGeoJsonForCheckin(checkin: any): any {
    if (checkin.polygon && checkin.polygon.geometry) {
      // Si el check-in tiene un polígono, utiliza las coordenadas del polígono
      return {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: checkin.polygon.geometry.coordinates // Asumiendo que el check-in tiene el polígono en este formato
            },
            properties: {
              name: checkin.customer && checkin.customer.name ? checkin.customer.name : 'Unknown',
            }
          }
        ]
      };
    } else {
      // Si no tiene polígono, solo utiliza las coordenadas del punto
      return {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [checkin.loc.coordinates[0], checkin.loc.coordinates[1]]
            },
            properties: {
              name: checkin.customer && checkin.customer.name ? checkin.customer.name : 'Unknown',
            }
          }
        ]
      };
    }
  }
}
