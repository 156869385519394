import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SettlementService } from '../../../services/settlement.service';

@Component({
  selector: 'app-settlement-change-status',
  templateUrl: './settlement-change-status.component.html',
  styleUrls: ['./settlement-change-status.component.css']
})
export class SettlementChangeStatusComponent implements OnInit {

  payments_status = [
    { value: 'Pendiente', viewValue: 'Pendiente' },
    { value: 'Pagado', viewValue: 'Pagado' },
  ];
  selectedStatus: string;
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private settlementService: SettlementService,
    public dialogRef: MatDialogRef<SettlementChangeStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    console.log(this.data);
    this.createForm();
    this.form.patchValue(this.data);
    this.selectedStatus = this.data.status;
    this.listernsFormValue();
  }

  listernsFormValue(): void {
    this.form.get('status').valueChanges.subscribe(value => {
      console.log(value);
      this.selectedStatus = value;
      if (value === 'Pagado') {
        this.form.get('remaining').setValue(0);
      } else {
        this.form.get('remaining').setValue(this.data.remaining);
      }
    });
  }
  
  createForm() {
    const form = {
      date:               ['', Validators.required],
      status:             [''],
      remaining:          [''],
    };
    this.form = this.fb.group(Object.assign(form));
  }

  update(): void {
    const update = this.form.getRawValue();
    console.log(update);
    this.settlementService.updateSettlementPending(this.data._id, update).subscribe(res => {
      console.log('Liquidacion editada: ', res);
      this.dialogRef.close({res: 'confirm'});
    }, err => console.error('Error al edit liquidacion: ', err));
  }

  onCloseCancel() {
    this.dialogRef.close('cancel');
  }

}
