import { SelectCategoryComponent } from './../../../accounting/seat-edit/select-category/select-category.component';
import { Router } from '@angular/router';
import { SettlementService } from './../../../../services/settlement.service';
import { PaymentService } from './../../../../services/payment.service';
import { AdvancePaymentService } from './../../../../services/advancePayment.service';
import { AdvancePayment } from './../../../../models/advancePayment';
import { Settlement } from './../../../../models/settlement';
import { InventoryService } from './../../../../services/inventory.service';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect, MatDialog } from '@angular/material';
import { takeUntil } from 'rxjs/operators';

import { CategoryService } from '../../../../services/category.service';
import { SeatService } from '../../../../services/seat.service';
import { UserService } from '../../../../services/user.service';
import { CollectionCenterService } from '../../../../services/collectionCenter.service';
import { GLOBAL } from '../../../../services/global';

import { Category } from '../../../../models/category';
import { Seat } from '../../../../models/seat';
import 'rxjs/add/observable/of';
import * as _ from 'lodash';
import { Inventory } from '../../../../models/inventory';
import { Payment } from '../../../../models/payment';
@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.css']
})
export class StepThreeComponent implements OnInit, AfterViewInit, OnDestroy {

  public identity;
  public identi: any;
  public ident: any;

  /**-------------select------------------*/
  public bankCtrl: FormControl = new FormControl();
  public bankFilterCtrl: FormControl = new FormControl();
  public data: any[];
  public filteredBanks: ReplaySubject<Category[]> = new ReplaySubject<
    Category[]
  >(1);
  @ViewChild('singleSelect') singleSelect: MatSelect;
  private _onDestroy = new Subject<void>();
  /*---------------------------------------------*/

  public category_id;
  public currentTotalDebit;
  public currentTotalCredit;
  public category: any = {};
  public currency;
  seatForm: FormGroup;
  totalPayment;
  public totalDebit = 0;
  public totalCredit = 0;
  public diff = 0;
  myFormValueChanges$;
  period;
  settlement: any = {};
  toggle = false;
  public indexSelected = {};
  public categories: Category[] = [];
  public filteredCategories: Category[] = [];
  public save = true;
  public seat = false;
  public collectionCenter: any;
  public cashCountId;
  form: FormGroup;
  payments_status = [
    { value: 'Pendiente', viewValue: 'Pendiente' },
    { value: 'Pagado', viewValue: 'Pagado' },
  ];
  selectedStatus: string;

  constructor(
    private _userService: UserService,
    private fb: FormBuilder,
    private _weightNotesService: InventoryService,
    private _advancePaymentService: AdvancePaymentService,
    private _paymentService: PaymentService,
    private _settlementService: SettlementService,
    private _seatService: SeatService,
    private _categoryService: CategoryService,
    private _collectionCenterService: CollectionCenterService,
    private router: Router,
    public dialog: MatDialog,
  ) {
    this.identity = this._userService.getCompany();
    this.ident = this._userService.getIdentity();
    this.category = {} as Category;
    const data = localStorage.getItem('settlement');
    const index = localStorage.getItem('indexSelectedAdvancePayments');
    this.indexSelected = index !== null ? JSON.parse(index) : {};
    this.settlement = data !== null ? JSON.parse(data) : {};
    console.log('settlement step 3: ', this.settlement);
  }

  ngOnInit() {
    this._userService.getUser(this.ident._id).subscribe(res => {
      this.identi = res;
      console.log(res);
      if (this.settlement.collectionCenter) {
        this._collectionCenterService.getCollectionCenter(this.settlement.collectionCenter).subscribe( res => {
          this.collectionCenter = res;
          console.log(this.collectionCenter);
          this.settlement.cashCount = this.collectionCenter.cashCount._id;
          this.settlement.collectionCenter = this.settlement.collectionCenter;
        });
      } else {
        delete this.settlement.collectionCenter;
        delete this.settlement.collectionCenterS;
      }
    });

    this.createForm();
    this.createSeatForm();
    this.getCategories();
    this.listeners();
    this.currency = GLOBAL.currency;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.form.patchValue(this.settlement);
    }, 100);
  }

  listeners(): void {
    this.form.valueChanges.subscribe(() => {
      this.settlement = Object.assign(this.settlement, this.form.value);
      this.settlement.total_net = this.getTotalNet(this.settlement, this.settlement.total);
    });

    this.seatForm.get('SeatCategory').valueChanges.subscribe(value => {
      console.log('change: ', value);
      this.totalDebit =  parseFloat(Number(_.sumBy(this.seatForm.get('SeatCategory').value, 'debit')).toFixed(2));
      this.totalCredit = parseFloat(Number(_.sumBy(this.seatForm.get('SeatCategory').value, 'credit')).toFixed(2));
      this.diff = parseFloat((this.totalDebit - this.totalCredit).toFixed(2));
      this.diff = isNaN(Number(this.diff)) ? 0 : Math.abs(this.diff);
    });
  }

  onStatusChange(value: string) {
    this.selectedStatus = value;
  }
  
  createForm() {
    const form = {
      date:               ['', Validators.required],
      status:             ['Pagado'],
      observations:       [''],
      remaining:          [''],
      pending:            [''],
      freight:            [''],
    };

    const specialFields = {
      other_deductions:   [''],
      contributions:      [''],
      export_costs:       [''],
      export_cert:        [''],
      financial_expenses: [''],
      check_number: [''],
    };

    this.form = this.fb.group(Object.assign(form,
        this.identity._id === '5f80c3091052d10488531338' ||
        this.identity._id === '616661122f820561d402aecf' || // * Gestopp info
        this.identity._id === '615362588a948929889a3c40'    // * Cocajuncal
        ?
        specialFields
        :
        {}
      ));
  }

  saveData(): void {
    const form = this.form.getRawValue();
    this.settlement = Object.assign(this.settlement, form);
    this.settlement.total_net = this.getTotalNet(this.settlement, this.settlement.total);
    console.log('new settlement: ', this.settlement);
    localStorage.setItem('settlement', JSON.stringify(this.settlement));
  }

  getTotalNet(settlement: any, total): number {
    let total_net = total;
    const numbers = _.pick(settlement, ['cost_per_beneficiary', 'freight', 'contributions', 'export_costs',
      'export_cert', 'financial_expenses', 'other_deductions', 'total_deductions']);
    Object.keys(numbers).forEach(key => {
      numbers[key] = isNaN(numbers[key]) ? 0 : numbers[key];
      total_net -= numbers[key];
    });
    return parseFloat(Number(total_net).toFixed(2));
  }

  submitForm(): void {
    const settlement: Settlement = this.settlement;
    console.log('settlement: ', settlement);
    this._settlementService.addSettlement(settlement).subscribe(res => {
      console.log('liquidacion agregada: ', res);
      this.addPayments(res._id);
      this.updateWeightNotes(res._id);
      this.createSeat(res._id);
      this.save = false;
      setTimeout(() => {
        localStorage.removeItem('settlement');
        localStorage.removeItem('indexSelectedAdvancePayments');
        localStorage.removeItem('indexSelectedWeightNotes');
        this.router.navigateByUrl('/administracion/liquidaciones');
      }, 500);
    });
  }

// settlementId: string
  addPayments = (settlementId: string): void => {
    let totalPayment = 0;
    let capitalPay = 0;
    if (this.settlement.total_deductions > this.settlement.total) {
      totalPayment = this.settlement.total;
    } else {
      totalPayment = this.settlement.total_deductions;
    }
    let paymentValue = 0;
    let interestPaid = 0;
    for (let i = 0; i < this.settlement.aps.length; i ++ ) {
      console.log(this.settlement.aps[i].id);
      this._advancePaymentService.getAdvancePayment(this.settlement.aps[i].id).subscribe(res => {
        const current: AdvancePayment = res;
        let temp = 0;
        console.log(this.settlement.aps[i].id);

        if (res._id === this.settlement.aps[i].id) {
            paymentValue = this.settlement.aps[i].paymentValue.toFixed(2);
            console.log(`El id de res (${res._id}) coincide con el índice (${i}), ` +
                        `asignando paymentValue (${paymentValue}) al objeto res.`);

/*             if (totalPayment >= current.total_balance) {
              temp = totalPayment - current.total_balance;
              paymentValue = current.total_balance;
            } else {
              temp = current.total_balance - totalPayment;
              paymentValue = totalPayment;
            } */

            if (Number(paymentValue) >= Number(current.total_interest)) {
              interestPaid = Number(current.total_interest);
              const cp = Number(paymentValue) - Number(current.total_interest);
              const cVal = Number(current.current_capital.toFixed(2));
              if (current.current_capital > cp) {
                current.current_capital = Number(cVal) - Number(cp.toFixed(2));
              } else {
                current.current_capital =  Number(cp.toFixed(2)) - Number(cVal);
              }
              capitalPay = Number(paymentValue) - Number(current.total_interest);
              current.total_interest = 0;
            } else {
              interestPaid = -(paymentValue - current.total_interest);
              current.total_interest = Number(current.total_interest) - Number(paymentValue);
              current.current_capital = Number(current.current_capital.toFixed(2));
              capitalPay = 0;
            }

            console.log(`restante: ${temp} => Abono #${i} - ${paymentValue}`);
            const payment: any = {};
            payment['mount'] = Number(current.mount);
            payment['payment'] = Number(paymentValue);
            payment['advancePayment'] = current._id;
            payment['coffeefarmer'] = this.settlement.coffeefarmer_id;
            payment['capital'] = Number(capitalPay);
            payment['current_capital'] = Number(current.current_capital.toFixed(2));
            payment['interest_paid'] = Number(interestPaid);
            payment['observations'] = '';
            payment['outstanding_balance'] = parseFloat(Number(current.total_balance - paymentValue).toFixed(4));
            payment['date'] = this.settlement.date;
            payment['liquidation'] = settlementId;

            current.total_balance = parseFloat(Number(current.total_balance - paymentValue).toFixed(4));

            if (current.total_payment) {
              current.total_payment = Number(current.total_payment) + Number(paymentValue);
            } else {
              current.total_payment = Number(paymentValue);
            }

            totalPayment = Number(totalPayment) - Number(paymentValue);
            if (totalPayment <= 0) {
              console.log('END!!!: ', totalPayment);
            }
            console.log(payment, current);
            this._paymentService.addPayment(payment).subscribe((response: Payment) => {
              console.log('Abono agregado: ', response);
              this.updateAp(current);
            }, err => console.error('Error al agregar payment: ', err));
        }

      });

        if (totalPayment <= 0) {
          console.log('END!!!: ', totalPayment);
          break;
        }
    }
  }

  updateAp(ap): void {

    // if (this.identity._id === '5f49cc3d6952eb738a3b4485' || this.identity._id === '5d781d0ed186853bb08054ad') {
      if (ap.total_balance > 0 && ap.total_interest === 0) {
          const i = ap.i_porcent / 100;
          const mi = ap.total_balance * i;
          const tm = mi / 12;
          const ixd = parseFloat((tm / 30).toFixed(8));
          ap.interest = ixd * 100;
      }
    // }

    if (ap.total_balance > 0 && ap.total_balance < 1) {
      ap.total_balance = 0;
      ap.interest = 0;
      ap.total_interest = 0;
    }

    if (ap.total_balance > 0 && ap.total_balance < ap.mount) ap.payment_status = 'Parcial';

    else if (ap.total_balance <= 0) {
      ap.payment_status = 'Pagado';
      ap.total_balance = 0;
      ap.total_interest = 0;
      ap.interest = 0;
      ap.current_capital = 0;
      ap.liquidated = true;
    } else {
      ap.payment_status = 'Pendiente';
    }

    console.log('el ap ', ap);

    this._advancePaymentService.editAdvancePayment(ap._id, ap).subscribe((res) => {
      console.log('anticipo actualizado', res);
    }, err => console.error('Error al actualizar anticipos', err));
  }

  createPayments(settlementId: string): void {

      let totalPayment = this.settlement.total_deductions;
      this._advancePaymentService.getAdvancePaymentPerCoffeeFarmer(this.settlement.coffeefarmer_id).subscribe(res => {
        const advancePayments = _.orderBy(res, ['date'], ['asc']);
        console.log('anticipos de productor: ', advancePayments);
        for (let i = 0; i < advancePayments.length; i ++ ) {
          const current: AdvancePayment = advancePayments[i];
          const temp = totalPayment - current.total_balance;
          const paymentValue = temp >= 0 ? current.total_balance : totalPayment;
          console.log(`restante: ${temp} => Abono #${i} - ${paymentValue}`);
          const payment: any = {};
          payment['mount'] = current.mount;
          payment['payment'] = paymentValue;
          payment['advancePayment'] = current._id;
          payment['coffeefarmer'] = this.settlement.coffeefarmer_id;
          payment['observations'] = '';
          payment['outstanding_balance'] = parseFloat(Number(current.total_balance - paymentValue).toFixed(4));
          payment['date'] = this.settlement.date;
          payment['liquidation'] = settlementId;

          this._paymentService.addPayment(payment).subscribe(res_ => {
            console.log('Abono agregado: ', res_);
            this._advancePaymentService.recalculateAdvancePayment(current._id, true, false);
          });
          totalPayment -= paymentValue;
          if (totalPayment <= 0) {
            console.log('END!!!: ', totalPayment);
            break;
          }
        }
      }, err => console.log('Error al obtener anticipos por productor: ', err));

  }

  updateWeightNotes(settlementId: string): void {
    const settlement: Settlement = this.settlement;
    this.settlement.weight_notes.forEach((wnId, index) => {
      this._weightNotesService.getInventory(wnId).subscribe((wn: Inventory) => {
        // * Se verifica los qq de la nota de peso para determinar su estado liquidado y parcial.
        wn['partialData'] = wn.hasOwnProperty('partialData') ? wn.partialData : { dry: false, settlement: false };
        wn.liquidation = wn.liquidation.concat(settlementId);
        if (wn.total_net_sett > wn.total_net_qq) {
          wn.total_net_sett = wn.total_net_qq;
        }
        wn.total_net_sett = parseFloat(Number(Number(wn.total_net_sett) - Number(settlement.qqForWn[index])).toFixed(6));
        wn.total_net_sett = wn.total_net_sett < 0 ? 0 : wn.total_net_sett;
        wn.total_net_sett = wn.total_net_sett > wn.total_net_qq ? wn.total_net_qq : wn.total_net_sett;
        if (wn.total_net_sett === 0) {
          wn.liquidated = true;
          wn.entry = 'Propio';
          wn.partialData.settlement = false;
        } else if (wn.total_net_sett === wn.total_net_qq ) {
          wn.liquidated = false;
          wn.entry = 'Depósito';
          wn.partialData.settlement = false;
        } else {
          wn.entry = 'Depósito';
          wn.liquidated = false;
          wn.partialData.settlement = true;
        }
        this._weightNotesService.editInventory(wn._id, wn).subscribe(response => {
        console.log('Nota de peso actualizada: ', response);
        }, err => console.error('Error al editar nota de peso: ', err));
      }, err => console.error('Error al obtener nota de peso para actualizar: ', err));
    });
  }

  createSeat(settlement: string): void {
    const seat = this.seatForm.getRawValue();
    if (this.seat && this.seatForm.valid && this.seatForm['controls'].SeatCategory['controls'].length > 1) {
      if (this.totalCredit !== this.totalDebit) {
        console.error('Total credit !== total debit');
      } else {
        seat.date_voucher_n = new Date(this.form.get('date').value).getTime();
        seat.date_voucher = this.form.get('date').value;
        seat.abbreviation = 'LQ';
        seat.total_debit = this.totalDebit;
        seat.total_credit = this.totalCredit;
        seat.settlement = settlement;
        seat.description = this.form.get('observations').value;
        seat.tag = 'Liquidación';
        this._seatService.addSeat(seat).subscribe(response => {
          this.updateCategories(seat.SeatCategory);
          console.log('Asiento creado: ', response);
          // this._router.navigateByUrl('/contabilidad/asientos');
        }, err => console.error('Error al crear asient[o: ', err));
      }
    }
  }

  updateCategories(categories: any[]): void {
    categories.forEach(current => {
      const category: Category = current.category;
      const sign = category.description === 'Pasivos' ||
        category.description === 'Capital' ||
        category.description === 'Ingresos' ? -1 : 1;
      category.balance += parseFloat((Number(current.debit) - Number(current.credit)).toFixed(4)) * sign;
      this._categoryService.editCategory(category._id, category).subscribe(response => {
        console.log('Saldo de cuenta actualizado: ', response);
      }, err => console.error('Error al actualizar saldo de cuenta: ', current, err));
    });
  }

  ngOnDestroy(): void {
    if (this.save) {
      this.saveData();
    }
  }

  // * ----------------------------------- A S I E N T O --------------------------------------

  createSeatForm(): void {
    this.seatForm = this.fb.group({
      description:  [''],
      SeatCategory: this.fb.array([
        this.getUnit(),
        this.getUnit(),
      ]),
    });
  }

  private getUnit() {
    const numberPatern = '^[0-9.,]+$';
    return this.fb.group({
      category:     ['', Validators.required],
      name:         [''],
      description:  [''],
      debit:        [0, [Validators.pattern(numberPatern)]],
      credit:       [0, [Validators.pattern(numberPatern)]],
    });
  }

  addUnit() {
    const control = <FormArray>this.seatForm.controls['SeatCategory'];
    control.push(this.getUnit());
  }

  removeUnit(i: number) {
    const control = <FormArray>this.seatForm.controls['SeatCategory'];
    control.removeAt(i);
  }

  clearAllUnits() {
    const control = <FormArray>this.seatForm.controls['SeatCategory'];
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    control.clearValidators();
    control.push(this.getUnit());
  }

  openCategoryModal(category: any, index: number): void {
    const dialogRef = this.dialog.open(SelectCategoryComponent, {
      width: '500px',
      height: '200px',
      data: {
        categories: this.categories
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response !== undefined) {
        const name = `${response.code}-${response.name}`;
          const faControl = (<FormArray>this.seatForm.controls['SeatCategory']).at(index);
          faControl['controls'].category.setValue(response);
          faControl['controls'].name.setValue(name);
      }
    });
  }

  getCategories() {
    this._categoryService.getLedgerCategories().subscribe(res => {
      this.categories = res as Category[];
      this.filteredCategories = res as Category[];
    }, error => console.error('Error al obtener cuentas: ', error));
  }

}
