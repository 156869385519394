import { Component, OnInit } from '@angular/core';

import { GLOBAL } from '../../services/global';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user';
import { UploadService } from '../../services/upload.service';
import { Company } from '../../models/company';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  providers: [UserService, UploadService]
})
export class NavigationComponent implements OnInit {
  public user: User;
  public company: Company;
  public identity;
  public token;
  public status;
  public url: string;
  public filesToUpload: Array<File>;
  public isImageLoading;
  imageToShow: any;
  public image;

  constructor(
    private _userService: UserService,
    private _uploadService: UploadService,
    private _router: Router,
  ) {
    this.identity = this._userService.getIdentity();
    this.company = this._userService.getCompany();
    this.token = this._userService.getToken();
    this.image = this.company === null ? '' : this.company.image;
    this.user = this.identity;
    this.url = GLOBAL.url;
  }

  ngOnInit() {
   this.getImage();
  }
  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageToShow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }
  getImage() {
          this.isImageLoading = true;
          this._uploadService.getImage(this.image).subscribe(data => {
            this.createImageFromBlob(data);
            this.isImageLoading = false;
          }, error => {
            this.isImageLoading = false;
            console.log(error);
              if (error.statusText === 'Unauthorized') {
              this.token = null;
              localStorage.removeItem('identity');
              sessionStorage.removeItem('token');
              localStorage.removeItem('company');
              localStorage.clear();

              this._router.navigate(['/']);
              location.reload();
            }
          });
  }

  logout() {
    localStorage.removeItem('identity');
    localStorage.removeItem('user');
    sessionStorage.removeItem('token');
    localStorage.clear();

    this.identity = null;
    this.token = null;
  }
}
