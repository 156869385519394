import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Socket } from 'ngx-socket-io';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
/* import { io, ManagerOptions, Socket, SocketOptions } from 'socket.io-client'; */


@Injectable()
export class SocketIoService extends Socket {
  public socket: Socket;
  public url = GLOBAL.url;
  public token;

constructor() {
    super({ url: GLOBAL.url, options: {
      query: {
        token: `Bearer ${localStorage.getItem('token')}`
      }
    } });
  }

/*   constructor(
  ) {
    const connectionOptions: Partial<ManagerOptions & SocketOptions> = {
      secure: true,
      transports : ["websocket"],
      rejectUnauthorized: true,
      forceNew: false,
      reconnectionDelay: 1000 * 15
    };
    try {
      this.socket = io(this.url, connectionOptions);
      this.socket.connect();
      this.socket.on('connect', () => {
        console.log('connected!');
      });

      this.socket.on('connect_error', (err: Error) => {
        console.error('Socket io error', err.name, err.message);
      });
    } catch (err) {
      console.log('Error en la conexion con sockets', err)
    }

  }

  listen = (eventName: string) => {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data) => subscriber.next(data))
    });
  } */

  listen = (eventName: string) => {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data) => subscriber.next(data))
    });
  }

  getToken() {
    const token = sessionStorage.getItem('token');

    if (token !== 'undefined') {
      this.token = token;
    } else {
      this.token = null;
      localStorage.removeItem('identity');
      sessionStorage.removeItem('token');
      localStorage.removeItem('company');
      localStorage.clear();
    }
    return this.token;
  }
}
