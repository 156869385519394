import { Component, OnInit } from '@angular/core';

import { GLOBAL } from '../../services/global';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user';
import { UploadService } from '../../services/upload.service';
import { UserRules } from '../../models/user-rules';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  providers: [UserService, UploadService]
})
export class SidebarComponent implements OnInit {
  public company;
  public identity;
  public token;
  public status;
  public isImageLoading;
  imageToShow: any;
  public image;
  public currentUser: User;

  constructor(
    private _userService: UserService,
    private _uploadService: UploadService
  ) {
    this.identity = this._userService.getIdentity();
    console.log('identity: ', this.identity);
    /* this._userService.getUser(this.identity._id).subscribe(res => {
      console.log('real user: ', res);
      this.currentUser = res;
    }, err => console.error('Error al obtener usuario: ', err)); */
    this.company = this.identity.company;
  }

  ngOnInit() {
    this.getCompany();
  }

  logout() {
    localStorage.removeItem('identity');
    sessionStorage.removeItem('token');
    localStorage.clear();
    this.identity = null;
    this.token = null;
  }

  getCompany() {
      this._userService.getCompanyUser(this.company._id).subscribe(
        response => {
          if (!response) {
          } else {
            this.company = response;
            const company = JSON.stringify(this.company);
            this.image = this.company.image;
            this.getImage(this.image);
            localStorage.setItem('company', company);
          }
        }
      );
  }
  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageToShow = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getImage(image) {
    this.isImageLoading = true;
    this._uploadService.getImage(image).subscribe(data => {
      this.createImageFromBlob(data);
      this.isImageLoading = false;
    }, error => {
      this.isImageLoading = false;
      console.log(error);
    });
  }
}
