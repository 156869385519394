import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';

import { GLOBAL } from '../../../../services/global';
import { AdvancePayment } from '../../../../models/advancePayment';
import { Payment } from '../../../../models/payment';

import { PaymentService } from '../../../../services/payment.service';
import { AdvancePaymentService } from '../../../../services/advancePayment.service';
import { PrintService } from '../../../../services/print.service';

@Component({
  selector: 'app-payment-add-v1',
  templateUrl: './payment-add-v1.component.html',
  styleUrls: ['./payment-add-v1.component.css']
})
export class PaymentAddV1Component implements OnInit {
  public form: FormGroup;
  public currency = GLOBAL.currency;
  public exceded = false;
  public advancePayment: AdvancePayment;
  public payment = {} as Payment;

  constructor(
    private _advancePaymentService: AdvancePaymentService,
    private _paymentService: PaymentService,
    private _printService: PrintService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PaymentAddV1Component>,
    @Inject(MAT_DIALOG_DATA) public data: AdvancePayment,
  ) {
    this.advancePayment = _.cloneDeep(this.data);
  }

  ngOnInit() {
    console.log('ap', this.data);
    this.createForm();
    this.listernersFormValues();
  }

  listernersFormValues(): void {
    this.form.get('payment').valueChanges.subscribe(value => {
      const payment = value;
      if (payment > this.data.total_balance) {
        this.form.controls['payment'].setErrors({'incorrect': true});
        this.exceded = true;
      } else {
        this.exceded = false;
        this.form.controls['payment'].clearValidators();
        this.form.controls['payment'].setValidators(Validators.required);
        /* const outstanding_balance = this.data.total_balance - payment;
        this.form.get('outstanding_balance').setValue(outstanding_balance); */
        this.calculate(value);
      }
    });

    this.form.get('payFull').valueChanges.subscribe(status => {
      if (status) {
        this.form.controls['payment'].disable();
        this.form.get('payment').setValue(this.data.total_balance);
      } else {
        this.form.get('payment').setValue(0);
        this.form.controls['payment'].enable();
      }
    });
  }

  calculate(paymentValue): void {
    this.advancePayment = _.cloneDeep(this.data);
    const ap = this.advancePayment;
    const outstandingInterest = ap.total_interest - paymentValue;

    ap.total_balance -= paymentValue;
    ap.total_balance = ap.total_balance < 0 ? 0 : ap.total_balance;
    ap.total_interest = outstandingInterest > 0 ? outstandingInterest : 0;

    this.payment.outstanding_balance = ap.total_balance;
    this.form.get('outstanding_balance').setValue(ap.total_balance);

    // * Se pagan todos los intereses acumulados.
    if (outstandingInterest < 0) {
        const capitalPaid = Math.abs(outstandingInterest);
        this.payment.interest_paid = this.data.total_interest;
        this.payment.capital = capitalPaid;
        ap.current_capital -= capitalPaid;
    } else { // * No se pagan todos los intereses.
        this.payment.interest_paid = paymentValue;
        this.payment.capital = 0;
    }
    ap.interest = ap.projected !== true ? ((ap.current_capital * ap.i_porcent) / 12) / 30 : ap.interest;
  }

  updateAp(): void {
    const ap = this.advancePayment;
    if (ap.total_balance > 0 && ap.total_balance < 1) {
      ap.total_balance = 0;
      ap.interest = 0;
      ap.total_interest = 0;
    }

    if (ap.total_balance > 0 && ap.total_balance < ap.mount) ap.payment_status = 'Parcial';

    else if (ap.total_balance <= 0) {
      ap.payment_status = 'Pagado';
      ap.total_balance = 0;
      ap.total_interest = 0;
      ap.interest = 0;
      ap.current_capital = 0;
      ap.liquidated = true;
    } else {
      ap.payment_status = 'Pendiente';
    }
    this._advancePaymentService.editAdvancePayment(ap._id, ap).subscribe((res) => {
      console.log('anticipo actualizado', res);
      this.dialogRef.close(true);
    }, err => console.error('Error al actualizar anticipos', err));
}

  addPay = (): void => {
    const payment: Payment = Object.assign(this.form.getRawValue(), this.payment);
    payment.payment = Number(payment.payment);
    payment.mount = this.data.mount;
    payment.advancePayment = this.data._id;
    payment.coffeefarmer = this.data.coffeefarmer._id;
    payment.current_capital = this.advancePayment.current_capital;
    if (this.advancePayment.total_payment) {
      this.advancePayment.total_payment = Number(this.advancePayment.total_payment) + Number(payment.payment);
    } else {
      this.advancePayment.total_payment = Number(payment.payment); 
    }
    console.log('payment', payment);
    console.log('advancePayment', this.advancePayment);
    this._paymentService.addPayment(payment).subscribe((res: Payment) => {
      this.print(res);
      this.updateAp();
    }, err => console.error('Error al agregar payment: ', err));
  }

  createForm = (): void => {
    this.form = this.fb.group({
      date:                 ['', Validators.required],
      payment:              ['', Validators.required],
      outstanding_balance:  [{value: '', disabled: true}, Validators.required],
      observations:         [''],
      payFull:              [''],
    });
  }

  print(payment: Payment) {
    this._printService.getPayment(payment._id);
  }

}
