import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import decode from 'jwt-decode';
import { GLOBAL } from './global';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AuthService {

  public url: string;
  constructor(
    public jwtHelper: JwtHelperService,
    private router: Router,
    private http: HttpClient
  ) { this.url = GLOBAL.url; }

  public isAuthenticated(): boolean {
    const token = sessionStorage.getItem('token');
  
    return !this.jwtHelper.isTokenExpired(token);
  }

  checkToken(): Observable<boolean> {
    console.log('se hace!!!');
      return this.http.get<{ valid: boolean }>(`${this.url}/auth/verify-token`).pipe(
          map(response => response.valid),
          catchError(() => of(false))
      );
  }

  logout(): void {
    sessionStorage.removeItem('token');
    localStorage.removeItem('identity');
    localStorage.removeItem('company');
    localStorage.clear();
    this.router.navigateByUrl('/');
    location.reload();
}
}
